<template>
  <div class="font-base text-base transition-colors min-h-screen flex justify-between bg-gray-100 flex-col">
      <div>
        <router-view/>
      </div>
      <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Header,
    Footer,
  },
}
</script>
