<template>
  <header class="relative pb-2 bg-white">
    <div class="max-w-screen-2xl px-5 mx-auto">
      <div v-if="isStart" class="flex items-center justify-between">
        <button title="Zur Startseite zurückkehren" @click="start()" class="w-48 sm:w-56 md:w-auto focus:ring-4 ring-offset-4 ring-primary-200">
          <img alt="Schulbeihilfenrechner Logo" src="/images/logo.svg" width="350" height="110" class="sm:-mt-2 mb-1 sm:mb-3"/>
        </button>
        <a href="https://ooe.arbeiterkammer.at" title="Arbeiterkammer OÖ" target="_blank" rel="noopener" class="w-12 sm:w-12 md:w-auto my-4 focus:ring-4 ring-offset-4 ring-primary-200">
          <img alt="Arbeiterkammer OÖ Logo" src="/images/ak-logo.svg" width="80" height="67" />
        </a>
      </div>
      <div v-else class="flex items-center justify-between">
        <button title="Zur Startseite zurückkehren" @click="start()" class="w-48 sm:w-56 md:w-auto focus:ring-4 ring-offset-4 ring-primary-200">
          <img alt="Schulbeihilfenrechner Logo" src="/images/logo.svg" width="250" height="78" class="sm:-mt-2 mb-1 sm:mb-3"/>
        </button>
        <a href="https://ooe.arbeiterkammer.at" title="Arbeiterkammer OÖ" target="_blank" rel="noopener" class="w-12 sm:w-12 md:w-auto my-4 focus:ring-4 ring-offset-4 ring-primary-200">
          <img alt="Arbeiterkammer OÖ Logo" src="/images/ak-logo.svg" width="55" height="45" />
        </a>
      </div>
    </div>
    <template v-if="showCalculation">
      <span class="absolute bottom-0 left-0 w-full h-2 bg-primary-200"></span>
      <span class="steps-js absolute bottom-0 left-0 h-2 bg-primary-500" 
        :style="{width:  (100 / $store.getters.getSteps.length * $store.getters.getStepCount ) + '%'}"
      ></span>
    </template>
    <span v-else class="steps-js absolute bottom-0 left-0 w-full h-2 bg-primary-500"></span>
  </header>
</template>


<script>
export default {
  props: {
      isStart: Boolean,
      showCalculation: Boolean,
  },
  methods: {
    start() {
      sessionStorage.removeItem('formData');
      sessionStorage.removeItem('formResult');
      sessionStorage.removeItem('formStep');
      sessionStorage.removeItem('identifier');

      this.$router.push('/');

      this.$store.dispatch('changeComponent', 'start');
    },
  }
}
</script>
