import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Start',
        component: () => import('../views/Start.vue'),
    },
    {
        path: '/schulbeihilfe',
        name: 'Schulbeihilfe',
        component: () => import('../views/Calculator.vue'),
        props: { type: 'basic' }
    },
    {
        path: '/besondere-schulbeihilfe',
        name: 'BesondereSchulbeihilfe',
        component: () => import('../views/Calculator.vue'),
        props: { type: 'special' }
    },
    {
        path: '/ueber',
        name: 'Introduction',
        component: () => import('../views/Introduction.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/NotFound.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
